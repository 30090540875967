<fieldset>
    <div class="button-status-wrapper">
        <button [disabled]="showLoadingIndicator" class="app-btn" (click)="launchProjectInApp()">
            <div class="app-btn-icon-box">
                <img [src]="appIconPath">
            </div>
            <div>{{ btnTitle }}</div>
        </button>

        <div *ngIf="statusMsg.length > 0" class="status-msg-container app-btn-row-container">
           <img *ngIf="showLoadingIndicator" src="../../../assets/loading-indicator-4-light.svg">
           {{ statusMsg }}
        </div>

        <div *ngIf="hasRunningSessions" class="session-controls-container app-btn-row-container">
            <!--
            <button [disabled]="showLoadingIndicator" (click)="save()" *ngIf="showSaveButton">
                <i class="fa fa-floppy-o" aria-hidden="true"></i>
            </button>
            -->
            <button [disabled]="showLoadingIndicator" (click)="close()">
                <i class="fa fa-times-circle" aria-hidden="true"></i>
            </button>
        </div>

    </div>
    
</fieldset>