<li>
    <div class="header">
        <h3>
            {{ project.name }}
            <img *ngIf="deleteProjectInProgress" src="../../../assets/loading-indicator.svg">
        </h3>

        <div class="project-menu" (mouseover)="showProjectMenu(true)" (mouseout)="showProjectMenu(false, true)">
            <i class="fa fa-bars" aria-hidden="true"></i>
            <ul *ngIf="projectMenuVisible" class="project-menu-dropdown">
                <!--<li (click)="editEmuDb(this.project)"><i class="fa fa-edit" aria-hidden="true"></i> Add sessions</li>-->
                <!--<li (click)="editProject(this.project)"><img class="button-icon" src="assets/gitlab-icon.png" /> Edit project</li>-->
                <li (click)="deleteProject()"><i class="fa fa-trash-o" aria-hidden="true"></i> Delete project</li>
            </ul>
        </div>
    </div>
    <div class="project-main-panel">
        
        <div class="applications-container">
            <h4>
                Applications
            </h4>

            <div *ngFor="let hsApp of hsApplications">
                <app-appctrl [hsApp]="hsApp" [project]="project" [projectItem]="this"></app-appctrl>
            </div>
        </div>

        <div *ngIf="false" class="applications-container">
            <h4>
                Operations
            </h4>

            <div *ngFor="let op of projectOperations">
                
                <button [disabled]="showLoadingIndicator" class="app-btn" (click)="op.callback()">
                    <div class="app-btn-icon-box">
                        <img [src]="appIconPath">
                    </div>
                    <div>{{ op.title }}</div>
                </button>

            </div>
        </div>

        <div class="gitlab-project-container">
            <h4>Operations</h4>
            <div class="gitlab-menu-container">
                <ul>
                    <li>
                        <div (click)="transcribeDialog(this.project)">
                            <div class="list-button">
                                <i class="fa fa-file-text-o" aria-hidden="true"></i>
                                &nbsp;
                                Auto-transcribe
                            </div>
                        </div>
                    </li>
                    <li>
                        <div (click)="editEmuDb(this.project)">
                            <div class="list-button">
                                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                &nbsp;
                                Sessions
                            </div>
                        </div>
                    </li>
                    <li>
                        <div (click)="manageBundleLists(this.project)">
                            <div class="list-button">
                                <i class="fa fa-headphones" aria-hidden="true"></i>
                                &nbsp;
                                Assign annotation tasks
                            </div>
                        </div>
                    </li>
                    <li>
                        <div (click)="manageMembers(this.project)">
                            <div class="list-button">
                                <i class="fa fa-users" aria-hidden="true"></i>
                                &nbsp;
                                Members
                            </div>
                        </div>
                    </li>
                    <!--
                    <li>
                        <div (click)="sessionsDialog(this.project)">
                            <div class="list-button">
                                <i class="fa fa-microphone" aria-hidden="true"></i>
                                &nbsp;
                                Sessions !
                            </div>
                        </div>
                    </li>
                    -->
                    
                    <li>
                        <div (click)="openSprScriptsDialog(this.project)">
                            <div class="list-button">
                                <i class="fa fa-microphone" aria-hidden="true"></i>
                                &nbsp;
                                Manage recording scripts
                            </div>
                        </div>
                    </li>
                    <!--
                    <li>
                        <a href="{{ projectMembersUrl }}" target="_blank">
                            <div class="list-button">
                                <img class="button-icon" src="assets/gitlab-icon.png" />
                                &nbsp;
                                Members
                            </div>
                        </a>
                    </li>
                    
                    <li>
                        <a href="{{ project.web_url }}" target="_blank">
                            <div class="list-button">
                                <img class="button-icon" src="assets/gitlab-icon.png" />
                                &nbsp;
                                View data repository
                            </div>
                        </a>
                    </li>
                    -->
                </ul>
            </div>
        </div>

        <div class="project-members-list">
            <h4>Members <a href="{{ project.web_url }}/-/project_members"><i class="fa fa-user-plus add-members-btn"></i></a></h4>
            <ul>
                <li *ngFor="let member of members">
                    <i class="fa fa-user" aria-hidden="true"></i>
                    &nbsp;
                    <a href="{{ member.web_url }}">{{ member.name }}</a>
                </li>
            </ul>
        </div>
        
    </div>
    
</li>